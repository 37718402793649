import React, { useEffect, useState } from "react";
import {InputLabel, Select, FormControl, MenuItem} from '@mui/material';

import './Controls.css';

const VideoControl = ({videoEnabled, mediaList, handleDevice, video}) => {
    const [defaultCamera, setDefaultCamera] = useState(video);

    const renderVideoState = () => {
        return (
            <div className='camera-controls-title'>
                Video source - 
                <span className={videoEnabled ? 'enabled' : videoEnabled === null ? 'waiting' : 'disabled'}>
                    {videoEnabled ? 'Enabled' : videoEnabled === null ? 'Waiting' : 'Disabled'}
                </span>
            </div>
        )
    }

    useEffect(() => {
        if (mediaList?.camera[0]) {
            setDefaultCamera(video || mediaList?.camera[0].value);
        }
    }, [mediaList, video]);

    return (
        <FormControl fullWidth className="camera-control">
            <InputLabel sx={{ top: 4 }}>
                {renderVideoState()}
            </InputLabel>
            <Select
                defaultValue={defaultCamera}
                value={video}
                onChange={(e) => handleDevice(e, 'camera')}
                size="small"
            >
                {mediaList?.camera && mediaList.camera.map(device => (
                    <MenuItem
                        value={device.value}
                        key={device.value}
                    >
                        {device.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default VideoControl;
