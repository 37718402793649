import axios from 'axios';

const apiRequest = axios.create({
  baseURL: process.env.REACT_APP_CLUSTER_URL,
  headers: {
    'Content-Type': 'application/json',
    'Auth-Token': process.env.REACT_APP_CLUSTER_TOKEN
  }
});

const getStreamingToken = async (room_name) => {
  let response;
  window.s0 = true;
  try {
    response = await apiRequest.post('/token/v2/', {
      room_name
    });
  } catch (error) {
    window.s0 = false;
    apiRequest.defaults.baseURL = 'https://auth.s1.sceenic.co';
    response = await apiRequest.post('/token/v2/', {
        room_name
    });
  }
  return response;

}

export { getStreamingToken }
