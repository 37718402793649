import React, {useEffect, useRef, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { ThemeProvider, Box, Container, Fab, Tooltip } from '@mui/material';
import { ScreenshotMonitor, CameraAlt, Phone, Mic, MicOff, Videocam, VideocamOff, Settings, AspectRatio, SyncAlt  } from '@mui/icons-material';
import {withRouter} from 'react-router';
import html2canvas from 'html2canvas';
import canvas2image from "canvas2image-2";
import './Footer.css';
import {resetStore} from '../../Store/Reducers/ParticipantReducer';
import Timer from "../Timer/Timer";
import Chat from '../Chat/Chat';
import Menu from './_components/Menu/Menu'
import DeviceSelector from './_components/DeviceSelector/DeviceSelector'
import VideoQuality from "../VideoQuality/VideoQuality";

import {
    disableAudio,
    disableVideo,
    enableAudio,
    enableVideo,
    isAudioEnabled,
    startScreenSharing,
    stopScreenSharing,
} from "../../WT";

import theme from '../MUI/Theme';

const Footer = ({
    history,
    localParticipant,
    participantPublished,
    participants,
    connectedToSession,
    isViewer,
}) => {
    const {sessionId, constraints} = useSelector(state => state.participant)
    const [audioEnabled, setAudioEnabled] = useState(true);
    const [videoEnabled, setVideoEnabled] = useState(true);
    const [inited, setInited] = useState(false);
    const [userShareScreen, setUserShareScreen] = useState(false);
    const [showVideoQualityModal, setShowVideoQualityModal] = useState(false);
    const [mediaDevicesShown, setMediaDevicesShown] = useState(false);
    const [menuOpened, setMenuOpened] = useState(false);
    const dispatch = useDispatch();
    const screenSharingRef = useRef();
    const leaveCallRef = useRef();

    useEffect(() => {
        if (localParticipant) {
            if (audioEnabled) {
                const elem = document.getElementById(`${localParticipant.participantId}-muted-microphone`);

                if (elem) {
                    elem.style.display = 'none'
                }
            } else {
                const elem = document.getElementById(`${localParticipant.participantId}-muted-microphone`);

                if (elem) {
                    elem.style.display = 'block'
                }
            }
        }
    }, [audioEnabled, localParticipant]);

    useEffect(() => {
        const keyboardListener = (e) => {

            if (document.activeElement.tagName === 'TEXTAREA') {
                return
            }

            if (!isViewer && participants.length > 1 && e.keyCode === 77) { // M
                toggleAudio();
            }

            if (!isViewer && participants.length > 1 && e.keyCode === 86) { // V
                toggleVideo()
            }


            if (e.keyCode === 76) { // L
                leaveCallRef.current();
            }

            if (e.keyCode === 83) { // S
                screenSharingRef.current();
            }

        }

        document.addEventListener('keyup', keyboardListener, false);

        return () => {
            document.removeEventListener('keyup', keyboardListener, false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (localParticipant) {
            if (videoEnabled) {
                const avatar = document.getElementById(`${localParticipant.participantId}-show-avatar`);
                const elem = document.getElementById(`${localParticipant.participantId}-muted-video`);

                if (elem) {
                    elem.style.display = 'none'
                }

                if (avatar) {
                    avatar.style.display = 'none';
                }
            } else {
                const avatar = document.getElementById(`${localParticipant.participantId}-show-avatar`);
                const elem = document.getElementById(`${localParticipant.participantId}-muted-video`);

                if (elem) {
                    elem.style.display = 'block'
                }

                if (avatar) {
                    avatar.style.display = 'block';
                }
            }
        }
    }, [videoEnabled, localParticipant])

    useEffect(() => {
        if (localParticipant) {
            const elem = document.getElementById(`video-${localParticipant.participantId}`);

            if (elem) {
                if (userShareScreen) {
                    elem.classList.add('screen-share-video')
                } else {
                    elem.classList.remove('screen-share-video')
                }
            }
        }
    }, [userShareScreen, localParticipant])

    const toggleQualityMenu = () => {
        setShowVideoQualityModal(true)
    }

    const toggleMenu = () => {
        setMenuOpened(prev => !prev)
    }

    const toggleMediaSettingsMenu = () => {
        setMediaDevicesShown(prev => !prev)
    }

    const hideMenuAndTabs = () => {
        toggleMenu()
        setShowVideoQualityModal(false)
        setMediaDevicesShown(false)
    }

    const toggleAudio = () => {
        const audioEnabled = isAudioEnabled()
        if (audioEnabled) {
            disableAudio();
            setAudioEnabled(false);
        } else {
            enableAudio();
            setAudioEnabled(true);
        }
    };

    const toggleVideo = useCallback(async () => {
        if (videoEnabled) {
            disableVideo();
            setVideoEnabled(false);
        } else {
            enableVideo();
            setVideoEnabled(true);
        }
    }, [videoEnabled]);

    const snapshot = async () => {
        const canvas = await html2canvas(document.querySelector('#root'), {
            allowTaint: true,
            imageTimeout: 0,
        });
        return canvas2image.saveAsPNG(canvas);
    }

    const toggleScreenSharing = async () => {
        try {
            if (!userShareScreen) {
                await startScreenSharing(localParticipant.participantId);
                setUserShareScreen(true)
            } else {
                stopScreenSharing(localParticipant.participantId);
                setUserShareScreen(false)
            }

        } catch (e) {
            setUserShareScreen(false)
        }
    }

    screenSharingRef.current = toggleScreenSharing;

    const leaveCall = () => {
        if (/^arcadyan-demo/.test(sessionId)) {
            dispatch(resetStore());
            history.replace('/arcadyan');
            return
        }

        window.location.href = `/thank-you?room=${sessionId}`;
    }

    leaveCallRef.current = leaveCall;

    useEffect(() => {
        if (localParticipant && constraints && !inited && participants.length > 1) {
            if (!constraints.audio) {
                toggleAudio();
            }

            if (!constraints.video) {
                toggleVideo();
            }

            setInited(true);
        }
    }, [localParticipant, constraints, inited, toggleVideo, participants])

    const getBtn = ({
        ariaLabel='',
        backgroundColor='rgba(25, 118, 210, .7)',
        onClick=() => {},
        icon=null,
        tooltip='',
        className='',
        children=null,
        tabIndex=0,
    }) => {
        return (
            <Tooltip
                title={tooltip}
                placement='top'
            >
                <Fab
                    size="medium"
                    color="primary"
                    aria-label={ariaLabel}
                    sx={{marginLeft: 1, marginRight: 1, backgroundColor}}
                    onClick={onClick}
                    className={className}
                >
                    {icon}
                    {children}
                </Fab>
            </Tooltip>
        );
    }



    return (<>
        <DeviceSelector
            visible={mediaDevicesShown}
            localParticipantId={localParticipant ? `video-${localParticipant.participantId}` : null}
            hideModal={() => setMediaDevicesShown(false)}
        />
        <Menu
            visible={menuOpened}
            hideModal={hideMenuAndTabs}
            tabs={[
                {
                    title: 'Media devices',
                    icon: <SyncAlt fontSize='large' sx={{marginRight: 2}} />,
                    action: toggleMediaSettingsMenu,
                    close: () => setMediaDevicesShown(false)
                },
                {
                    title: 'Video quality',
                    icon: <AspectRatio fontSize='large' sx={{marginRight: 2}} />,
                    action: toggleQualityMenu,
                    close: () => setShowVideoQualityModal(false)
                },
            ]}
        />
        <VideoQuality show={showVideoQualityModal} showModal={setShowVideoQualityModal}/>

        <ThemeProvider theme={theme}>
            <Box sx={{
                position: 'fixed',
                backgroundColor: 'rgba(156,39,176, .45)',
                height: {
                    xs: '144px',
                    sm: '80px',
                },
                width: '100%',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 2,
            }}>
                <Container sx={{ display: 'flex' }}>
                    <Box className="footer-left" sx={{
                        right: {
                            xs: '50%',
                            sm: 'auto',
                        },
                        left: {
                            xs: 'auto',
                            sm: 0,
                        },
                    }}>
                        {
                            getBtn({
                                ariaLabel: 'Share Screen',
                                onClick: toggleScreenSharing,
                                icon: <ScreenshotMonitor fontSize="large" />,
                                tooltip: 'Share Screen (S)',
                                tabIndex: 1,
                            })
                        }
                        {
                            getBtn({
                                ariaLabel: 'Take A Snap',
                                onClick: snapshot,
                                icon: <CameraAlt fontSize="large" />,
                                tooltip: 'Take A Snap',
                                tabIndex: 2,
                            })
                        }
                    </Box>
                    <Box className="footer-center">
                        { participants.length > 1 &&
                            getBtn({
                                ariaLabel: 'Mute / Unmute',
                                onClick: toggleAudio,
                                icon: !!audioEnabled ? <Mic fontSize="large" /> : <MicOff fontSize="large" />,
                                tooltip: 'Mute / Unmute (M)',
                                tabIndex: 3,
                            })
                        }
                        { participants.length > 1 &&
                            getBtn({
                                ariaLabel: 'Start / Stop camera (V)',
                                onClick: toggleVideo,
                                icon: videoEnabled ? <Videocam fontSize="large" /> : <VideocamOff fontSize="large" />,
                                tooltip: 'Start / Stop camera (V)',
                                tabIndex: 5,
                            })
                        }
                        {
                            getBtn({
                                ariaLabel: 'Leave call',
                                onClick: leaveCall,
                                icon: <Phone fontSize="large" />,
                                backgroundColor: '#EA6861',
                                className: 'footer-fab-leave',
                                tooltip: 'Leave call (L)',
                                tabIndex: 4,
                            })
                        }
                    </Box>

                    <Box
                        sx={{
                            fontSize: '9px',
                            fontWeight: 700,
                            color: 'rgba(255, 255, 255, .4)',
                            position: 'absolute',
                            right: 8,
                            top: 2,
                        }}
                    >
                        {window.s0 ? 'server: s0' : 'server: s1'}
                    </Box>
                    <Box className="footer-right" sx={{
                        left: {
                            xs: '50%',
                            sm: 'auto',
                        },
                        right: {
                            xs: 'auto',
                            sm: 0,
                        },
                    }}>
                        {participantPublished ? <Timer/> : null}
                        { participantPublished && connectedToSession ? (
                            <Chat participants={participants} />
                        ): null }

                        {  !isViewer && participantPublished ? getBtn({
                                ariaLabel: 'Settings',
                                onClick: toggleMenu,
                                icon: <Settings fontSize="large" />,
                                tooltip: 'Settings',
                                tabIndex: 7,
                            })
                        : null }

                        { participantPublished ? (
                            <div className='footer-control-menu-container'>
                                <div onClick={() => setShowVideoQualityModal(!showVideoQualityModal)}
                                        className='menu-item'>Change Video Resolution
                                </div>
                            </div>
                        ) : null }
                    </Box>

                    <Box sx={{
                        fontSize: '9px',
                        fontWeight: 700,
                        color: 'rgba(255, 255, 255, .4)',
                        position: 'absolute',
                        right: 8,
                        bottom: 2,
                    }}>
                        {sessionId ? `room: ${sessionId}` : ''}
                    </Box>
                </Container>
            </Box>
        </ThemeProvider>
    </>)
}

export default withRouter(Footer)
