import React, { useState, useEffect, } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import './DeviceSelector.css';
import {useSelector} from 'react-redux';
import * as Sentry from "@sentry/react";
import {changeMediaDevices, getDevicesList, onDevicesUpdate} from "../../../../WT";

const DeviceSelector = (props) => {
	const { visible, localParticipantId, hideModal, } = props;
	const {audioOnly} = useSelector(state => state.participant);

	const [tab, setTab] = useState(0);
	const [audio, setAudio] = useState('default')
	const [video, setVideo] = useState('default')
	const [output, setOutput] = useState('default')
	const [mediaList, setMediaList] = useState({
		camera: [],
		microphone: [],
		speaker: [],
	})

	const getDevices = async () => {
		try {
			const devices = await getDevicesList();

			setMediaList(devices)

			return devices
		} catch (e) {
			console.log('error - getDevices', e.message);
			Sentry.captureException(e);
		}
	}

	useEffect(() => {
		getDevices()

		onDevicesUpdate((devices)=> {
			setMediaList(devices)
		})
	}, [])

	const handleChange = (event, newValue) => {
		setTab(newValue);
	};

	const handleAudioDevice = async (event) => {
		const value = event.target.value

		try {
			await changeMediaDevices(value, 'microphone', localParticipantId)

			setAudio(value);
		} catch (e) {
			console.log('error - handleAudioDevice', e.message);
			Sentry.captureException(e);
		}
	};

	const handleVideoDevice = async (event) => {
		const value = event.target.value

		try {
			await changeMediaDevices(value, 'camera', localParticipantId)

			setVideo(value);
		} catch (e) {
			console.log('error - handleVideoDevice', e.message);
			Sentry.captureException(e);
		}
	};

	const handleOutputDevice = async (event) => {
		const value = event.target.value

		try {
			await changeMediaDevices(value, 'speaker', localParticipantId)

			setOutput(value);
		} catch (e) {
			console.log('error - handleOutputDevice', e.message)
			Sentry.captureException(e);
		}
	};

	const useStyles = makeStyles((theme) => ({
		root: {
			width: '33.3%',
			height: 55,
			color: '#FFFFFF',
			fontSize: 14,
			fontFamily: 'Gilroy-Medium',
			'@media (max-width: 1000px)': {
				fontSize: 11,
			}
		},
		indicator: {
			height: 1,
			background: '#FFFFFF',
		},
		input: {
			display: 'flex',
			alignItems: 'center',
			padding: '10px',
			width: '100%',
			height: 55,
			color: '#CCC',
			fontSize: 16,
			fontFamily: 'Gilroy-Regular',

			'&:before': {
				borderColor: '#082B44'
			},
			'&:after': {
				borderColor: '#CCC'
			}
		},
		formcontrol: {
			width: '100%',
			height: 55,
		},
	}));

	const classes = useStyles();

	return (
		<>
			<div
				className={`device-selector-wrapper ${visible ? 'opened' : ''}`}
				onClick={hideModal}
			/>
			<div className={`ds-content ${visible ? 'opened' : ''}`}>
				<Tabs
					classes={{
						indicator: classes.indicator
					}}
					value={tab}
					onChange={handleChange}
					centered
					aria-label="Media devices menu"
				>
					<Tab className={classes.root} label="Audio device" tabIndex={0} />
					{navigator.userAgent.indexOf('Firefox') === -1 ? <Tab className={classes.root} label="Output device" tabIndex={0} /> : null }
					{!audioOnly ? <Tab className={classes.root} label="Video device" tabIndex={0} /> : null }
				</Tabs>

				<div className='ds-content-container'>

					{tab === 0 && (
						<FormControl variant="filled" className={classes.formcontrol}>
							<Select
								className={classes.input}
								classes={{
									root: classes.input,
								}}
								labelId="demo-simple-select-label"
								id="demo-simple-select-filled"
								value={audio}
								onChange={handleAudioDevice}
							>
								{mediaList?.microphone && mediaList.microphone.map((device, index) => (
									<MenuItem
										value={device.value}
										key={index}
									>
										{device.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}

					{tab === 1 && (
						<FormControl variant="filled" className={classes.formcontrol}>
							<Select
								className={classes.input}
								classes={{
									root: classes.input,
								}}
								labelId="demo-simple-select-label"
								id="demo-simple-select-filled"
								value={output}
								onChange={handleOutputDevice}
							>
								{mediaList?.speaker && mediaList.speaker.map((device, index) => (
									<MenuItem
										value={device.value}
										key={index}
									>
										{device.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}

					{tab === 2 && !audioOnly && (
						<>
							<FormControl variant="filled" className={classes.formcontrol}>
								<Select
									className={classes.input}
									classes={{
										root: classes.input,
									}}
									labelId="demo-simple-select-label"
									id="demo-simple-select-filled"
									value={video}
									onChange={handleVideoDevice}
								>
									{mediaList?.camera && mediaList.camera.map((device, index) => (
										<MenuItem
											value={device.value}
											key={index}
										>
											{device.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</>
					)}
				</div>
			</div>
		</>
	)
};

export default DeviceSelector;
