import React, { useEffect, useState, useRef } from "react";
import { Chart, registerables } from "chart.js";
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import { onMosReport, enableWTStats } from "../../WT";
import colors from './colors';
import selectOptions from './selectOptions';
import './Chart.css';

Chart.register(...registerables);

const MAX_DATASET_LENGTH = 20;
const CHART_UPDATE_TIME = 1*1000;
const CHART_AXIS_UPDATE_TIME = 10*1000;

let lastChartUpdateTimestamp = 0;
let lastChartAxisUpdateTimestamp = 0;
let dataBuffer = {};

const ChartElement = ({ participans = []}) => {
    const chartRef = useRef();
    const [dataKey, setDataKey] = useState({type: '', name: ''});
    const [tmpData, setTmpData] = useState('');

    const handleSelect = (e) => {
        setDataKey(e.target.value);
    }

    function getTime() {
        return new Date().toLocaleTimeString('en-GB');
    }

    const setMinMaxY = (forceUpdate=false) => {
        if (!chartRef?.current) {
            return;
        }

        const chart = chartRef.current;

        chart.data.datasets.forEach((dataset, i) => {
            const data = dataset.data.filter(item => typeof item === 'number');

            if (i === 0 && forceUpdate) {
                chart.options.scales.y.suggestedMin = Math.min(...data) * .8;
                chart.options.scales.y.suggestedMax = Math.max(...data) * 1.2;
            }

            let minY = Math.min(...data) * .8;
            let maxY = Math.max(...data) * 1.2;

            if (minY < chart.options.scales.y.suggestedMin) {
                chart.options.scales.y.suggestedMin = minY;
            }

            if (maxY > chart.options.scales.y.suggestedMax) {
                chart.options.scales.y.suggestedMax = maxY;
            }

            if (data.length === 1) {
                minY = data[0] * .8;
                maxY = data[0] * 1.2;

                chart.options.scales.y.suggestedMin = minY;
                chart.options.scales.y.suggestedMax = maxY;
            }
        });
    }

    const updateChartStructure = (chart, currentData, allData) => {
        const NO_NAME = '-';
        const hasDataset = chart.data.datasets.find(item => currentData.participantId === item.participantId);

        console.log(hasDataset?.participantId, currentData.participantId);

        if (!hasDataset) {
            const color = colors.pop();
            colors.unshift(color);

            const participanName = participans.find(item => item.participantId === currentData.participantId)?.participantName;

            chart.data.datasets.push({
                label: `${participanName || NO_NAME} #${currentData.participantId}`,
                data: (new Array(MAX_DATASET_LENGTH)).fill(0),
                backgroundColor: color,
                borderColor: color,
                borderWidth: 1,
                participantId: currentData.participantId,
            });
        }

        for (let i = 0; i < chart.data.datasets.length; i++) {
            if (chart.data.datasets[i].label.split(' #')[0] === NO_NAME) {
                const participanName = participans.find(item => item.participantId === currentData.participantId)?.participantName;
                chart.data.datasets[i].label = `${participanName || NO_NAME} #${currentData.participantId}`;
            }
        }

        chart.data.datasets = chart.data.datasets.filter(dataset => {
            return dataset.participantId in allData;
        })
    }

    useEffect(() => {
        enableWTStats();

        onMosReport(({participantId, stats}) => {
            function getNumData(obj) {
                const result = {};

                for (const key in obj) {
                    if (typeof obj[key] === 'number') {
                        result[key] = Math.round(obj[key] * 100) / 100;
                    }
                }

                return result;
            }

            dataBuffer[participantId] = {
                participantId,
                timestamp: stats.detail.timestamp,
                video: getNumData(stats.detail.video),
                audio: getNumData(stats.detail.audio),
                data: getNumData(stats.detail.data),
            };

            if (stats.detail.timestamp - lastChartUpdateTimestamp > CHART_UPDATE_TIME) {
                setTmpData(dataBuffer);
                dataBuffer = {};
                lastChartUpdateTimestamp = stats.detail.timestamp;
            }

            if (stats.detail.timestamp - lastChartAxisUpdateTimestamp > CHART_AXIS_UPDATE_TIME) {
                setMinMaxY(true);
                lastChartAxisUpdateTimestamp = stats.detail.timestamp;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.destroy()
        }

        const ctx = document.getElementById('chart-root');

        const chart = new Chart(ctx, {
            type: 'line',
            scaleOverride: true,
            data: {
                labels: (new Array(MAX_DATASET_LENGTH)).fill(''),
                datasets: [],
            },
            options: {
                responsive: true,
                scales: {
                    y: {
                        beginAtZero: false,
                        suggestedMin: 0,
                        suggestedMax: 1,
                    },
                },
            },
        });

        chartRef.current = chart;

        return () => {
            chartRef.current.destroy();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataKey]);

    useEffect(() => {
        if (!chartRef?.current || !Object.keys(tmpData).length) {
            return;
        }

        if (!dataKey?.type) {
            return;
        }

        const chart = chartRef.current;

        for (const key in tmpData) {
            const currentData = {
                ...tmpData[key],
                participantId: key,
            };

            updateChartStructure(chart, currentData, tmpData);

            if (currentData.participantId) {
                if (chart.data.labels.length > MAX_DATASET_LENGTH) {
                    chart.data.labels.shift();

                    chart.data.datasets.forEach((dataset) => {
                        if (dataset.data.length > MAX_DATASET_LENGTH) {
                            dataset.data.shift();
                        }
                    });
                }

                chart.data.datasets.forEach((dataset) => {
                    if (currentData.participantId === dataset.label.split('#')[1]) {
                        dataset.data.push(currentData[dataKey.type][dataKey.name]);
                    }
                });

                setMinMaxY();
            }
        }

        chart.data.labels.push(getTime());
        chart.update();
    }, [tmpData, participans]);

    return (
        <div className="chart-wrapper">
            <canvas id="chart-root" />

            <FormControl sx={{margin: '0 8px', width: 'calc(100% - 16px)'}}>
                <InputLabel id="chart-select-label" className='chart-select-abel'>Parameter</InputLabel>
                <Select
                    labelId="chart-select-label"
                    id="chart-select"
                    value={dataKey}
                    label={`${dataKey.type} - ${dataKey.name}`}
                    onChange={handleSelect}
                >
                    {
                        selectOptions.map(item => (
                            <MenuItem value={item} key={`${item.type} - ${item.name}`}>
                                {`${item.type} - ${item.name}`}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </div>
    );
}

export default ChartElement;
