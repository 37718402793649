import React from "react";
import {InputLabel, Select, FormControl, MenuItem} from '@mui/material';

import './Controls.css';

const SpeakerControl = ({speaker, mediaList, handleDevice}) => {
    return (
        <FormControl fullWidth className="camera-control">
            <InputLabel sx={{ top: 4 }}>Speaker</InputLabel>
            <Select
                value={speaker}
                onChange={(e) => handleDevice(e, 'speaker')}
                size="small"
            >
                {mediaList?.speaker && mediaList.speaker.map(device => (
                    <MenuItem
                        value={device.value}
                        key={device.value}
                    >
                        {device.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default SpeakerControl;