const videoOptions = [
    "delta_jitter_ms_in",
    "delta_jitter_ms_out",
    "total_rtt_ms_out",
    "total_rtt_measure_out",
    "percent_packets_lost_in",
    "delta_packets_in",
    "delta_packets_lost_in",
    "total_packets_in",
    "total_packets_lost_in",
    "total_KBytes_in",
    "delta_KBytes_in",
    "total_KBytes_out",
    "delta_KBytes_out",
    "delta_ms_encode_frame_out",
    "total_time_encoded_out",
    "total_frames_encoded_out",
    "delta_ms_decode_frame_in",
    "total_frames_decoded_in",
    "total_time_decoded_in",
    "delta_nack_out",
    "delta_pli_out",
    "total_nack_out",
    "total_pli_out",
    "delta_nack_in",
    "delta_pli_in",
    "total_nack_in",
    "total_pli_in",
];

const audioOptions = [
    "level_in",
    "delta_jitter_ms_in",
    "delta_jitter_ms_out",
    "total_rtt_ms_out",
    "total_rtt_measure_out",
    "percent_packets_lost_in",
    "delta_packets_in",
    "delta_packets_lost_in",
    "total_packets_in",
    "total_packets_lost_in",
    "total_KBytes_in",
    "delta_KBytes_in",
    "total_KBytes_out",
    "delta_KBytes_out",
    "mos_in",
    "mos_emodel_in"
];

const dataOptions = [
    "total_KBytes_in",
    "total_KBytes_out",
    "delta_KBytes_in",
    "delta_KBytes_out",
    "delta_kbs_in",
    "delta_kbs_out",
    "delta_kbs_bandwidth_in",
    "delta_kbs_bandwidth_out",
    "delta_rtt_connectivity_ms_out",
    "total_rtt_connectivity_ms_out",
    "total_rtt_connectivity_measure_out"
];

export default [
    ...videoOptions.map(name => ({type: 'video', name})),
    ...audioOptions.map(name => ({type: 'audio', name})),
    ...dataOptions.map(name => ({type: 'data', name})),
];
