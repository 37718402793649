const colors = [
    'rgba(143, 113, 91, .8)',
    'rgba(212, 220, 169, .8)',
    'rgba(128, 173, 215, .8)',
    'rgba(244, 135, 75, .8)',
    'rgba(163, 88, 109, .8)',
    'rgba(164, 164, 191, .8)',
    'rgba(190, 217, 5, .8)',
    'rgba(218, 162, 218, .8)',
    'rgba(255, 159, 64, .8)',
    'rgba(153, 102, 255, .8)',
    'rgba(22, 35, 90, .8)',
    'rgba(75, 192, 192, .8)',
    'rgba(255, 206, 86, .8)',
    'rgba(54, 162, 235, .8)',
    'rgba(255, 99, 132, .8)',
];

export default colors;