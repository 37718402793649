import React, {useEffect, useMemo, useRef, useState} from 'react';
import { withRouter, useLocation } from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import qs from 'query-string';
import { Card, CardContent, Typography, Fab, Box, Container } from '@mui/material';
import { PersonAdd, PersonAddDisabled, Addchart } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material';

import theme from '../MUI/Theme';

import {resetStore, setParticipantKicked, setParticipantRejected} from '../../Store/Reducers/ParticipantReducer';
import Video from '../Video/Video';
import Chart from '../Chart/Chart';
import Footer from '../Footer/Footer';
import VideoQuality from "../VideoQuality/VideoQuality";
import './Session.css'
import {
    connectAsViewer,
    disconnect,
    connect,
    connectWithoutPublish,
    listenWtError,
    onConnected,
    onLocalConnectionResumed,
    onStreamCreated,
    onParticipantJoin,
    onLocalStreamCreated,
    onParticipantLeft,
    onParticipantRejected, publishRemoteParticipant, disconnectRemoteP
} from "../../WT";

const Session = ({history}) => {
    const {
        audioOnly,
        token,
        userName,
        sessionId,
        isAdmin,
        viewer,
        gettingToken,
    } = useSelector(state => state.participant);

    let [participants, setParticipants] = useState([]);
    let [localParticipant, setLocalParticipant] = useState(null);
    let [unApprovedParticipants, setUnapprovedParticipants] = useState([]);
    
    const [videoContainerWidth, setVideoContainerWidth] = useState(0);
    const [showStatsChart, setShowStatsChart] = useState(false);

    const [waitingState, setWaitingState] = useState(false);
    const [connectedToSession, setConnectedToSession] = useState(false);
    const isAdminRef = useRef(isAdmin);
    const dispatch = useDispatch();
    const sessionIdRef = useRef(sessionId);
    const location = useLocation();

    const room = qs.parse(location.search).room;

    const handleStatsChartSwitch = () => {
        setShowStatsChart(prev => !prev);
    }

    const leaveCall = () => {
        dispatch(resetStore());

        if (/^arcadyan-demo/.test(sessionId)) {
            window.location.href = '/arcadyan';
            return;
        }

        if (!room) {
            window.location.href = '/';
            return;
        }

        window.location.href = `${room}`;
    }

    useEffect(() => {
        return () => {
            disconnect()
        }
    }, []);

    useEffect(() => {
        setLocalParticipant(participants.find(participant => participant.local));
    }, [participants])

    useEffect(() => {
        if (!token && !gettingToken) {
            return leaveCall()
        }

        if (!token) {
            return;
        }

        isAdminRef.current = isAdmin;
        if (viewer) {
            connectAsViewer(token, userName)
            return;
        }

        if (isAdmin) {
            connect(token, userName)
        } else {
            connectWithoutPublish(token, userName, audioOnly)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, userName, isAdmin]);

    useEffect(() => {
        sessionIdRef.current = sessionId;
    }, [sessionId]);

    useEffect(() => {
        let participants = [];
        let unApprovedParticipants = []

        listenWtError((e) => {
            if (e.code === 107) {
                toast.error('Couldnt grant camera or video permission!', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error(e.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })

        onConnected((exParticipants) => {
            setConnectedToSession(true);

            if (isAdminRef.current) {
                unApprovedParticipants = [];
                exParticipants.forEach((p) => {
                    const exists = participants.find((participant) => p.participantId === participant.participantId);

                    if (!exists) {
                        unApprovedParticipants = [...unApprovedParticipants, p];
                    }
                })
            }

            onLocalConnectionResumed(() => {
                participants = [];
                setParticipants(participants);
            })

            onStreamCreated((participant) => {

                if (participant.local) {
                    setWaitingState(false)
                }

                unApprovedParticipants = unApprovedParticipants.filter(p => p.participantId !== participant.participantId)
                setUnapprovedParticipants([...unApprovedParticipants]);

                const alreadyExists = participants.find(p => p.participantId === participant.participantId);

                if(alreadyExists) {
                    const el = document.getElementById('video-' + participant.participantId);
                    el.srcObject = participant.stream
                } else {
                    participants = [...participants, participant];
                    setParticipants([...participants])
                }
            });

        });

        onParticipantJoin((params) => {
            const participantAlreadyExists = participants.find(p => p.participantId === params.participantId);

            if (isAdminRef.current && !participantAlreadyExists) {
                unApprovedParticipants = [...unApprovedParticipants, params]
                setUnapprovedParticipants(unApprovedParticipants)
            }

        });

        onLocalStreamCreated((params) => {
            const alreadyExists = participants.find((p) => p.participantId === params.participantId);
            if (!alreadyExists) {
                setParticipants([...participants, params])
            }
        })

        onParticipantLeft(({participantId}) => {
            participants = participants.filter(p => p.participantId !== participantId)
            setParticipants(participants);
            unApprovedParticipants = unApprovedParticipants.filter(p => p.participantId !== participantId);
            setUnapprovedParticipants(unApprovedParticipants)
        });

        onParticipantRejected(() => {
            if (participants.length === 1) {
                dispatch(setParticipantRejected(true));
            } else {
                dispatch(setParticipantKicked(true))
            }

            dispatch(resetStore())

            history.replace(`/${sessionIdRef.current}`)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (!viewer) {
            setWaitingState(!isAdmin);
        }
    }, [isAdmin, viewer])

    const widthMap = {
        1: {
            xs: 'calc(100% - 16px)',
            md: 'calc(60% - 16px)',
            xl: 'calc(80% - 16px)',
        },
        2: {
            xs: 'calc(100% - 16px)',
            md: 'calc(50% - 16px)',
            xl: 'calc(50% - 16px)',
        },
        3: {
            xs: 'calc(100% - 16px)',
            md: 'calc(35% - 16px)',
            xl: 'calc(48% - 16px)',
        },
        4: {
            xs: 'calc(100% - 16px)',
            md: 'calc(35% - 16px)',
            xl: 'calc(40% - 16px)',
        },
        9: {
            xs: 'calc(100% - 16px)',
            md: 'calc(50% - 16px)',
            xl: 'calc(27% - 16px)',
        },
        default: {
            xs: 'calc(100% - 16px)',
            md: 'calc(50% - 16px)',
            xl: 'calc(30% - 16px)',
        },
    }

    const memoizedRenderParticipant = useMemo(() => {
        if (!participants.length) {
            return null;
        }

        const externalParticipants = participants.filter(participant => !participant.local);
        const externalParticipantsCount = externalParticipants.length;

        const width = widthMap[externalParticipantsCount] || widthMap.default;

        setVideoContainerWidth(width);

        const makeCard = (participant, className='') => (
            <Card
                id={participant.participantId}
                key={participant.participantId}
                className={className}
                sx={{
                    backgroundColor: 'rgba(255, 255, 255, .1)',
                    position: 'relative',
                    width: width,
                    margin: 1,
                }}
            >
                <Box sx={{position: 'relative', height: 0, paddingTop: '56.25%'}}>
                    <Video
                        key={participant.participantId}
                        participants={participants}
                        participant={participant}
                        participantPublished={!waitingState}
                        connectedToSession={connectedToSession}
                    />
                </Box>
            </Card>
        )

        const result = externalParticipants.map(participant => makeCard(participant));

        if (localParticipant) {
            result.push(makeCard(localParticipant, 'session-my-video-card'));
        }

        return result;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participants, localParticipant]);

    const publishParticipant = (participantId) => {
        publishRemoteParticipant(participantId)
    };

    const disconnectRemoteParticipant = (participantId) => {
        disconnectRemoteP(participantId)
    }

    const once = (func) => {
        let calls = 1;
        return function () {
            if (calls > 0) {
                func.apply(null, arguments);
                calls--;
            }
        };
    };

    return (
        <>
            <ThemeProvider theme={theme} >
                <div className='app-bg-dark' />
                <div className='powered-by-container'>
                    <div className='powered-by-text'>Powered by</div>
                    <div className='logo-top'>
                    </div>
                </div>

                {participants.length > 1 &&
                    <div className='stats-chart-switch'>
                        <Addchart
                            fontSize="large"
                            color={showStatsChart ? 'primary' : 'inherit'}
                            onClick={handleStatsChartSwitch}
                        />
                    </div>
                }

                <Box sx={{maxHeight: 'calc(100vh - 50px)', overflow: 'auto', paddingTop: '5px'}}>
                    <Container
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            paddingBottom: 2,
                            marginTop: -1,
                            maxWidth: {
                                xs: 1792,
                                sm: 1792,
                                md: 1792,
                                xl: 1792,
                            },
                        }}
                    >
                        {memoizedRenderParticipant}

                        { showStatsChart && participants.length > 1 &&
                            <Card
                                sx={{
                                    position: 'relative',
                                    width: videoContainerWidth,
                                    margin: 1,
                                }}
                            >
                                <Box sx={{position: 'relative', height: 0, paddingTop: '56.25%'}}>
                                    <Chart participans={participants} />
                                </Box>
                            </Card>
                        }
                    </Container>
                </Box>

                {(unApprovedParticipants.length && isAdmin) ? (
                    <div className="unapproved-users-container">
                        {unApprovedParticipants.map((participant, index) => {
                            return (
                                <Card key={participant.participantId} sx={{marginBottom: 1}}>
                                    <CardContent>
                                        <div className='user-face' />
                                        <Typography
                                            variant="subtitle1"
                                            sx={{ paddingTop: 1, paddingBottom: 1, textAlign: 'center' }}
                                        >
                                            Do you want&nbsp;
                                            <span style={{ textDecoration: 'underline' }}>{participant.participantName}</span>
                                            &nbsp;to join this session?
                                        </Typography>
                                        <div className='approved-buttons'>
                                            <Fab
                                                tabIndex={0}
                                                size="medium"
                                                color="primary"
                                                aria-label="publish participant"
                                                onClick={once(() => publishParticipant(participant.participantId))}
                                            >
                                                <PersonAdd fontSize="large" />
                                            </Fab>
                                            <Fab
                                                tabIndex={0}
                                                size="medium"
                                                aria-label="disconnect remote participant"
                                                color="secondary"
                                                onClick={once(() => disconnectRemoteParticipant(participant.participantId))}
                                            >
                                                <PersonAddDisabled fontSize="large" />
                                            </Fab>
                                        </div>
                                    </CardContent>
                                </Card>
                            )
                        })}
                    </div>
                ) : null}
            </ThemeProvider>

            {waitingState ? <div className='waiting-container'>
                <div>Meeting organiser has been notified you are waiting.</div>
                <div>Please wait to be admitted in ...</div>
            </div> : null}

            <VideoQuality localParticipant={localParticipant}/>

            <Footer localParticipant={localParticipant}
                    participantPublished={true}
                    connectedToSession={true}
                    participants={participants}
                    isViewer={viewer}
            />
        </>
    )
};

export default withRouter(Session)
